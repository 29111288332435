<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card class="card_lesson">
        <b-card-title
          class="mb-1 font-weight-bold text-center d-flex flex-column align-items-center"
          title-tag="h2"
        >
          <img
            class="img_title_lesson"
            src="@/assets/images/logo/logo-jjc-grupo.svg"
            alt=""
          />
          Formulario de Registro de Lecciones Aprendidas
        </b-card-title>
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <b-row class="">
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  class="h-100 d-inline-block w-100"
                  name="ambit"
                  rules="requeridoE"
                >
                  <b-form-group label="Ambito" label-for="ambit" class="h-100">
                    <div
                      class="d-flex w-100 justify-content-center align-items-center mt-2"
                    >
                      <b-form-radio
                        v-model="dataItems.ambit"
                        name="ambit"
                        value="oficina"
                        @input="emptyInputs()"
                      >
                        Oficina Principal y Sedes
                      </b-form-radio>
                      <b-form-radio
                        v-model="dataItems.ambit"
                        name="ambit"
                        value="proyecto"
                        class="ml-2"
                        @input="emptyInputs()"
                      >
                        Proyecto
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                v-if="dataItems.ambit == 'proyecto'"
                class="d-flex flex-column"
              >
                <validation-provider
                  #default="{ errors }"
                  name="project"
                  rules="requeridoE"
                >
                  <b-form-group label="Proyecto" label-for="project" class="">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="proyectos"
                      label="code"
                      input-id="project"
                      :reduce="(proyectos) => proyectos.id"
                      placeholder="Proyecto"
                      v-model="dataItems.project_id"
                      class="select-obra"
                      ><template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" v-else class="">
                <validation-provider #default="{ errors }" name="area" rules="requeridoE">
                  <b-form-group label="Area" label-for="area" class="">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="areas"
                      label="text"
                      input-id="area"
                      :reduce="(areas) => areas.value"
                      placeholder="Area"
                      class="select-obra"
                      v-model="dataItems.area_name"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="register_by"
                  rules="requeridoE"
                >
                  <b-form-group label="Registrado por" label-for="register_by">
                    <b-form-input
                      v-model="dataItems.register_by"
                      id="register_by"
                      placeholder="Registrado por"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="requeridoE|correo"
                >
                  <b-form-group label="Email" label-for="email">
                    <b-form-input
                      v-model="dataItems.email"
                      id="email"
                      type="email"
                      placeholder="Email"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="date_specification"
                  rules="requeridoE"
                >
                  <b-form-group
                    label="Fecha de Registro"
                    label-for="date_specification"
                  >
                    <flat-pickr
                      id="date_specification"
                      v-model="dataItems.date_specification"
                      class="form-control"
                      :config="config"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider
                  #default="{ errors }"
                  name="division"
                  :rules="!isDisabledByAmbit ? 'requeridoE' : ''"
                >
                  <b-form-group label="Division" label-for="division">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="divisiones"
                      label="description"
                      input-id="division"
                      :reduce="(records) => records.abbreviation"
                      v-model="dataItems.division"
                      placeholder="Division"
                      :disabled="isDisabledByAmbit"
                      @input="changeDiv(dataItems.division)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="6" class="d-flex flex-column">
                <validation-provider
                  #default="{ errors }"
                  name="category"
                  :rules="!isDisabledByAmbit ? 'requeridoE' : ''"
                >
                  <b-form-group label="Título" label-for="category">
                    <v-select
                      v-if="dataItems.division != 'otros'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typesWorks"
                      label="description"
                      input-id="category"
                      v-model="dataItems.type_work"
                      :reduce="(typesWorks) => typesWorks.abbreviation"
                      placeholder="Título"
                      :disabled="isDisabledByAmbit"
                      class="select-obra"
                    >
                    </v-select>
                    <b-form-input
                      v-else
                      v-model="dataItems.type_work"
                      id="category"
                      placeholder="Título"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider
                  #default="{ errors }"
                  name="speciality"
                  :rules="!isDisabledByAmbit ? 'requeridoE' : ''"
                >
                  <b-form-group label="Especialidad" label-for="speciality">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="specialitys"
                      label="description"
                      input-id="speciality"
                      :reduce="(specialitys) => specialitys.abbreviation"
                      v-model="dataItems.speciality"
                      :disabled="isDisabledByAmbit"
                      placeholder="Especialidad"
                      class="select-obra"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider
                  #default="{ errors }"
                  name="typification"
                  rules="requeridoE"
                >
                  <b-form-group label="Tipificación" label-for="typification">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typifications"
                      label="description"
                      input-id="typification"
                      :reduce="(typifications) => typifications.abbreviation"
                      v-model="dataItems.typification"
                      placeholder="Tipificación"
                      class="select-obra"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" v-if="dataItems.typification == 'otros'">
                <validation-provider
                  #default="{ errors }"
                  name="typification"
                  rules="requeridoE"
                >
                  <b-form-group
                    label="Otros (Especificar)"
                    label-for="typification_others"
                  >
                    <b-form-input
                      v-model="dataItems.typification_others"
                      id="typification_others"
                      placeholder="Especificar"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

              </b-col>

              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  class="h-100 d-inline-block w-100"
                  name="is_security"
                  rules="requeridoE"
                >
                  <b-form-group
                    label="Es de SSOMA?"
                    label-for="is_security"
                    class="h-100"
                    style="font-weight: bolder; color: black; padding: 0.5rem; border-style: dashed;"
                  >
                    <div
                      class="d-flex w-50 justify-content-center align-items-center mt-1"
                    >
                      <span class="mr-2" style="font-weight: bolder; color: black">No</span>
                      <b-form-checkbox
                        v-model="dataItems.is_security"
                        name="is_security"
                        @input="changeTypification(dataItems.is_security)"
                        switch
                      >
                      </b-form-checkbox>
                      <span class="ml-2" style="font-weight: bolder; color: black">Si</span>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="12">
                <h5>Lección Aprendida</h5>
              </b-col>
              <b-col sm="12">
                <validation-provider
                  #default="{ errors }"
                  name="title_lesson"
                  rules="requeridoE"
                >
                  <b-form-group label="" label-for="title_lesson" class="mb-0">
                    <b-form-textarea
                      id="title_lesson"
                      :placeholder="tl_placeholder"
                      rows="4"
                      max-rows="4"
                      v-model="dataItems.title_lesson"
                      no-resize
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="12">
                <h5>Descripción de la Ocurrencia e Impacto</h5>
              </b-col>
              <b-col sm="12">
                <validation-provider
                  #default="{ errors }"
                  name="description_impact"
                  rules="requeridoE"
                >
                  <b-form-group
                    label=""
                    label-for="description_impact"
                    class="mb-0"
                  >
                    <b-form-textarea
                      id="description_impact"
                      :placeholder="descr_placeholder"
                      rows="4"
                      max-rows="4"
                      v-model="dataItems.description_impact"
                      no-resize
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Evaluación de las Causas</h5>
              </b-col>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="assessment_causes"
                  rules="requeridoE"
                >
                  <b-form-textarea
                    id="assessment_causes"
                    :placeholder="eval_placeholder"
                    rows="6"
                    max-rows="6"
                    v-model="dataItems.assessment_causes"
                    no-resize
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Mitigación del Impacto</h5>
              </b-col>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="impact_mitigation"
                  rules="requeridoE"
                >
                  <b-form-textarea
                    id="impact_mitigation"
                    :placeholder="mit_placeholder"
                    rows="6"
                    max-rows="6"
                    no-resize
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    v-model="dataItems.impact_mitigation"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Análisis y Conclusiones</h5>
              </b-col>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="conclusions"
                  rules=""
                >
                  <b-form-textarea
                    id="conclusions"
                    :placeholder="analisis_placeholder"
                    rows="6"
                    max-rows="6"
                    no-resize
                    v-model="dataItems.conclusions"                    
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <validation-observer #default="{ invalid }" ref="refFormObserverFiles">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="6">
                <h5>Archivos Adjuntos</h5>
              </b-col>
              <b-col
                md="6"
                class="d-flex justify-content-md-end justify-content-center"
              >
                <b-button @click="saveTemp" :variant="'success'" class="">
                  Guardar Archivo
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="requeridoE"
                >
                  <b-form-group label="Descripción" label-for="description">
                    <b-form-input
                      v-model="items.description"
                      id="description"
                      placeholder="Descripción"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="">
                <validation-provider
                  #default="{ errors }"
                  name="file"
                  rules="requeridoE"
                >
                  <b-form-group label="Archivo" label-for="file">
                    <b-form-file
                      v-model="items.file"
                      placeholder="Seleccione un archivo o arrastre aqui..."
                      drop-placeholder="Arrastre aqui..."
                      id="file"                     
                    />
                    <small class="text-warning"
                      >Maximo tamaño de archivo 20mb</small
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <b-row>
          <b-col md="12">
            <b-table
              class="position-relative"
              :hover="true"
              :items="files"
              responsive
              :fields="fields"
              show-empty
              empty-text="No existen"
              @sort-changed="sortChanged"
              no-border-collapse
            >
              <!-- Column: Actions -->
              <!-- <template v-slot:cell(nro)="{ item, field: { key, index } }">
                <span>{{index}}</span>
              </template> -->
              <template #cell(created_at)="data">
                <span>{{
                  moment(data.item.created_at).format("yyyy-MM-DD")
                }}</span>
              </template>
              <template #cell(actions)="data">
                <div class="d-flex align-items-center">
                  <b-button
                    size="sm"
                    class=""
                    v-b-tooltip.noninteractive.hover.top="'Eliminar'"
                    variant="flat-danger"
                    @click.prevent="deleteTemp(data.item)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col sm="3">
                  <b-form-group
                    label-cols="4"
                    label-cols-md="4"
                    label-size="md"
                    label="Entradas"
                    label-for="input-md"
                  >
                    <b-form-select
                      v-model="showEntrie"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="entries"
                      @change="changeSizePage"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="3" class="mt-75" style="font-size: 12px">
                  <span> {{ totalElements }} Registros en total</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalElements"
                    :per-page="showEntrie"
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                    @change="cambioPagina"
                    pills
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="15" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="15" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="p-1 d-flex justify-content-center">
            <b-button
              class="mr-2 mb-1 mb-md-0"
              variant="success"
              @click="saveData(dataItems)"
            >
              <span class="text-nowrap"> Guardar </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import LessonService from "@/services/LessonService";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import ProjectsService from "@/services/ProjectsService";
import DivisionService from "@/services/DivisionService";
import AreasService from "@/services/AreasService";
import flatPickr from "vue-flatpickr-component";
import TypeWorkService from "@/services/TypeWorkService";
import SpecialityLessonService from "@/services/SpecialityLessonService";
import TypificationService from "@/services/TypificationService";
const APIURL = process.env.APIURLFILE;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      api_url: APIURL,
      showLoading: false,
      tabIndex: 0,
      records: [],
      files: [],
      filesTemp: [],
      fields: [
        { key: "id", label: "Nro", sortable: false },
        { key: "description", label: "Descripción", sortable: false },
        { key: "created_at", label: "Fecha Actualización", sortable: false },
        { key: "file_name", label: "Nombre Archivo", sortable: false },
        { key: "actions", label: "Acciones" },
      ],
      prioridades: [
        {
          id: 1,
          name: "Alta",
        },
        {
          id: 2,
          name: "Media",
        },
        {
          id: 3,
          name: "Baja",
        },
      ],
      config: {
        plugins: [
          ShortcutButtonsPlugin({
            theme: "dark",
            button: [{ label: "Hoy" }],
            onClick(index, fp) {
              let date = index
                ? new Date(Date.now() + 24 * index * 60 * 60 * 1000)
                : new Date();
              fp.setDate(date);
              fp.close();
            },
          }),
        ],
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
            longhand: [
              "Domingo",
              "Lunes",
              "Martes",
              "Miércoles",
              "Jueves",
              "Viernes",
              "Sábado",
            ],
          },
          months: {
            shorthand: [
              "Ene",
              "Feb",
              "Mar",
              "Abr",
              "May",
              "Jun",
              "Jul",
              "Ago",
              "Sep",
              "Оct",
              "Nov",
              "Dic",
            ],
            longhand: [
              "Enero",
              "Febrero",
              "Мarzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
          },
        },
      },
      areas: [
        {
          text: "Comunicaciones",
          value: "COM",
        },
        {
          text: "Tecnologías de las Información",
          value: "TI",
        },
        {
          text: "Selección y Desarrollo",
          value: "SELDESA",
        },
        {
          text: "Nómina y Relaciones Laborales",
          value: "NOMRL",
        },
        {
          text: "Responsabilidad Social y Bienestar",
          value: "RRSS",
        },
        {
          text: "Archivo",
          value: "ARCHIV",
        },
        {
          text: "Impuestos y Cuentas por Pagar",
          value: "CXP",
        },
        {
          text: "Finanzas y Seguros",
          value: "FINANZAS",
        },
        {
          text: "Costos y Control de Gestión",
          value: "COCG",
        },
        {
          text: "Contabilidad",
          value: "CONTA",
        },
        {
          text: "Logística",
          value: "LOG",
        },
        {
          text: "Almacén Central y Transporte",
          value: "ALM",
        },
        {
          text: "Negocios",
          value: "NEG",
        },
        {
          text: "Propuestas",
          value: "PROP",
        },
        {
          text: "SSOMA",
          value: "SSOMAC",
        },
        {
          text: "Unidad de Equipos",
          value: "EQUIP",
        },
        {
          text: "Almacén Chilca",
          value: "ALMCHILCA",
        },
        {
          text: "Contratos",
          value: "CONTRATOS",
        },
        {
          text: "Legal",
          value: "LEGAL",
        },
        {
          text: "Planeamiento",
          value: "PLANEAM",
        },
      ],
      alltypesWorks: [],
      typesWorks: [],
      divisiones: [],
      specialitys: [],
      typifications: [],
      proyectos: [],
      showLoading: false,
			arrayFilterProyect: [],
      dataItems: {
        project_id: null,
        is_security: false,
        area_name: "",
        code: "",
        ambit: "proyecto",
        register_by: "",
        email: "",
        date_specification: new Date(),
        date_last_update: null,
        division: "",
        type_work: "",
        speciality: "",
        typification: "",
        typification_others: "",
        title_lesson: "",
        description_impact: "",
        assessment_causes: "",
        impact_mitigation: "",
        conclusions: "",
      },
      items: {
        description: "",
        file: null,
        lesson_id: null,
      },
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: "id",
      order: "desc",
      tl_placeholder: "",
      eval_placeholder: "",
      descr_placeholder: "",
      mit_placeholder: "",
      analisis_placeholder: "",
      alltypifications:[]
    }
  },
  computed:{
    isDisabledByAmbit(){
      return this.dataItems.ambit =='proyecto' ? false : true
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  mounted() {
    /* this.dataItems.project_id = parseInt(this.$route.params.id); */
    console.log("LOADED")
    this.getData();
    this.tl_placeholder = `El enunciado debe expresar la relación entre el resultado de un proceso o proyecto y los factores críticos, condiciones o causas que facilitaron u obstaculizaron el mismo.
    • Descripción de una situación final, resultado o consecuencia + verbo pasado introduciendo una relación (provenir de/depender de/a consecuencia de/etc.) + descripción de las condiciones o causas.`;
    this.descr_placeholder = `Descripción del impacto sobre las actividades de construcción o las actividades del área de soporte.`;
    this.eval_placeholder = `Para identificar las causas pueden realizar las siguientes preguntas:
    • ¿Qué/Cómo/Cuando/Donde + condujo a/generó/provocó/permitió/dificultó/etc. + dicho efecto?.`;
    this.mit_placeholder = `Debemos identificar las acciones que se deben realizar para mitigar el impacto.
• ¿Qué acciones se realizaron para afrontar el evento?
• ¿Qué acciones podemos realizar en futuro para estar listos ante un evento?`;
    this.analisis_placeholder = `• Análisis: interpretación de datos, tendencias, patrones o relaciones.
• Conclusiones: recomendaciones basadas en los hallazgos, acciones que se puedan tomar en futuro a partir de los resultados.`;
  },
  methods: {
  
    cambioPagina(e) {
      this.currentPage = e;
      this.totalElements = this.files.length;
    },
    changeSizePage() {
      this.totalElements = this.files.length;
    },
    sortChanged(data) {
      this.sort = data.sortBy;
      if (data.sortDesc) {
        this.order = "desc";
      } else this.order = "asc";
    },
    cancel() {
      this.$router.push("/lecciones");
    },
    emptyInputs(){
      this.dataItems.division = ""
      this.dataItems.type_work = ""
      this.dataItems.speciality = ""
      this.dataItems.project_id = null
      this.dataItems.area_name = ""
    },
    async getData() {
      this.arrayFilterProyect.push({
			keyContains: 'show_lesson',
			key: 'equals',
			value: true
		})
      const url1 =
        `?limit=10000&filter=`+JSON.stringify(this.arrayFilterProyect)
        const url = `?limit=10000`
      this.showLoading = true;
      const respProyectos = await ProjectsService.getProyectosAll(
        url1,
        this.$store
      );
      console.log("respProyectos", respProyectos);
      if (respProyectos) {
        this.proyectos = respProyectos.data.rows;
      }
      const respDivisions = await DivisionService.getDivisions(
        url,
        this.$store
      );
      console.log("respDivisions", respDivisions);
      if (respDivisions) {
        this.divisiones = respDivisions.data.rows;
      }
      const respTypeWork = await TypeWorkService.getTypeWorks(
        url,
        this.$store
      );
      console.log("respTypeWork", respTypeWork);
      if (respTypeWork) {
        this.alltypeWorks = respTypeWork.data.rows;
      }
      const respSpeciality = await SpecialityLessonService.getSpecialityLessons(
        url,
        this.$store
      );
      console.log("respSpeciality", respSpeciality);
      if (respSpeciality) {
        this.specialitys = respSpeciality.data.rows;
        const order = [
          "Movimiento de Tierras",
          "Civil",
          "Estructuras",
          "Mecánica",
          "Electrica e Instrumentacion",
          "Sanitaria",
          "Otras"
        ];

        this.specialitys = this.specialitys.sort((a, b) => order.indexOf(a.description) - order.indexOf(b.description));
      }
      const respTypifications = await TypificationService.getTypifications(
        url,
        this.$store
      );
      console.log("respTypifications", respTypifications);
      if (respTypifications) {
        this.alltypifications = respTypifications.data.rows;
        this.changeTypification(this.dataItems.is_security)
        // this.typifications.push({
        //   description: "Otros",
        //   abbreviation: "otros",
        // });
      }
      // let url1 = `?limit=100`
      // const respAreas = await AreasService.getAreas(url1, this.$store)
      // console.log('respAreas', respAreas)
      // if (respAreas) {
      //   this.areas = respAreas.data.rows
      // }

      this.showLoading = false;
    },
    // async selectProyect(id) {
    //   this.showLoading = true
    //   let url =
    //     `?limit=100&filter=` +
    //     JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
    //   const respAreas = await AreasService.getAreas(url, this.$store)
    //   console.log('respAreas', respAreas)
    //   if (respAreas) {
    //     this.areas = respAreas.data.rows
    //   }
    //   this.showLoading = false
    // },
    changeDiv(name) {
      this.dataItems.type_work = ""
      this.typesWorks = [];
      const typeWork = this.alltypeWorks.filter((e) => e.division_lesson.abbreviation == name);
      console.log("typeWork", typeWork);
      if (typeWork.length > 0) {
        console.log("typeWork[0].categorys", typeWork);
        this.typesWorks = typeWork;
      }
    },
    async saveData() {
      this.showLoading = true;
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "¿Seguro que desea registrar la lección?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, registralo",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              this.dataItems.date_specification = new Date(
                this.dataItems.date_specification
              );
              console.log(' this.dataItems,', this.dataItems)
              const save = await LessonService.saveLesson(
                this.dataItems,
                this.$store
              );
              console.log("save", save);
              if (save.status) {
                if (this.files.length > 0) {
                  let resp = null;
                  let datos = new FormData();
                  for (let index = 0; index < this.files.length; index++) {
                    const element = this.files[index];
                    datos.append("description", element.description);
                    datos.append("file", element.file);
                    datos.append("lesson_id", save.data.id);
                    resp = await LessonService.saveFile(datos, this.$store);
                    if (resp.status) {
                      datos.delete("description");
                      datos.delete("file");
                      datos.delete("lesson_id");
                    }
                  }
                  if (resp != null) {
                    if (resp.status) {
                      this.$swal({
                        title: "Registrado",
                        text:
                          "La lección aprendida correlativo nro " +
                          save.data.code +
                          " ha sido registrado con exito.",
                        icon: "success",
                        customClass: {
                          confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                      });
                      this.resetFormulario();
                      this.reloadPage();
                      // this.$router.push('/lecciones')
                    } else {
                      this.$swal({
                        title: "Error!",
                        text: "Ocurrió un error al registrar los archivos.",
                        icon: "error",
                        customClass: {
                          confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                      });
                    }
                  } else {
                    this.$swal({
                      title: "Error!",
                      text: "Ocurrió un error al guardar los archivos.",
                      icon: "error",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    });
                  }
                } else {
                  this.$swal({
                    title: "Registrado",
                    text:
                      "La lección aprendida correlativo nro " +
                      save.data.code +
                      " ha sido registrado con exito.",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.resetFormulario();
                  this.reloadPage();
                  // this.$router.push('/lecciones')s
                }
              } else {
                if(save.data.message == "No se encontraron usuarios para seguimiento de lecciones aprendidas"){
                  this.$swal({
                    title: "Error!",
                    text: save.data.message,
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }else{
                  this.$swal({
                    title: "Error!",
                    text: "Ocurrió un error al registrar los datos de los formularios.",
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }
              }
            }
          });
        } else {
          this.$swal({
            title: "Error!",
            text: "Campos obligatorios faltantes.",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
      this.showLoading = false;
    },
    resetFormulario() {
      (this.dataItems = {
        project_id: null,
        area_name: null,
        code: "",
        is_security: false,
        ambit: "proyecto",
        register_by: "",
        email: "",
        date_specification: new Date(),
        date_last_update: null,
        division: "",
        type_work: "",
        speciality: "",
        typification: "",
        typification_others: "",
        title_lesson: "",
        description_impact: "",
        assessment_causes: "",
        impact_mitigation: "",
        conclusions: "",
      }),
        (this.files = []);
      this.totalElements = 0;
      this.$refs.refFormObserverFiles.reset();
      this.items.description = "";
      this.items.file = null;
    },
    resetForm() {
      this.$refs.refFormObserverFiles.reset();
      this.items.description = "";
      this.items.file = null;
    },
    saveTemp() {
      console.log("before");
      console.log(this.items);
      this.$refs.refFormObserverFiles.validate().then(async (success) => {
        if (success) {
          console.log("this.items", this.items);
          this.files.push({
            id: this.files.length + 1,
            description: this.items.description,
            file: this.items.file,
            created_at: new Date(),
            file_name: this.items.file.name,
          });
          this.resetForm();
          this.totalElements = this.files.length;
        }
      });
    },
    reloadPage () {
      console.log('Recargando página');
      window.location.reload();
    },
    deleteTemp(data) {
      console.log("data", data);
      this.$swal({
        title: "¿Desea eliminar este archivo?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminalo",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteDataTemp(data.id);
        }
      });
    },
    deleteDataTemp(id) {
      console.log("id", id);
      this.records = this.records.filter((e) => e.id != id);
      this.currentPage = 1;
      this.totalElements = this.records.length;
      this.$swal({
        icon: "success",
        title: "Eliminado!",
        text: "El archivo ha sido eliminado.",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    changeTypification(is_ssomac) {
      this.typifications = [];
      this.dataItems.typification = null;
      const typification = this.alltypifications.filter((e) => e.is_ssomac == is_ssomac);
      if (typification.length > 0) {
        this.typifications = typification;
        this.typifications.push({
          description: "Otros",
          abbreviation: "otros",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.select-obra .vs__dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}
.shortcut-buttons-flatpickr-buttons {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.shortcut-buttons-flatpickr-button {
  background-color: rgb(115, 103, 240);
  border: 0px;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
}
.shortcut-buttons-flatpickr-button:hover {
  background-color: rgb(129, 119, 230);
}
@media screen and (min-width: 1024px) {
  .card_lesson .card-body {
    max-width: 1110px;
    margin: 0 auto;
  }
}
.card_lesson {
  background-color: #80808063;
}
.card_lesson .card-body {
  border-color: #001955;
  border-style: solid;
  border-width: 6px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: white;
}
.img_title_lesson {
  margin-bottom: 2rem;
  max-width: 100px;
}
</style>
